<template>
  <div class="d-flex flex-column">
    <div class="border d-flex justify-content-between align-center mb-2">
      <router-link :to="`/session/${this.worksessionId}/route`">
        <b-button class="mr-1" variant="outline-secondary" size="sm">
          <b-icon icon="arrow-left"></b-icon> volver
        </b-button>
      </router-link>
      <span class="trencadis-title">
        Mi Ruta <b>{{ this.mainRoute ? `: ${this.mainRoute.name}` : "" }}</b>
      </span>
      <div></div>
    </div>
    <v-form ref="form" v-model="valid" lazy-validation v-if="posData">
      <v-row class="mt-1">
        <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
          <v-dialog
            v-if="selectedSavingData"
            scrollable
            v-model="showConfirmDialog"
            max-width="80%"
          >
            <v-card>
              <v-card-title class="title_wrapper">
                <span class="text-h6">
                  {{
                    this.isFindSameChain
                      ? this.ISTHEREAVAILABLEBRANDS
                        ? "Quieres Añadir la Marca?"
                        : "Esta marca ya está dada de alta en este punto de venta, añadelo a tu ruta para rellenar la encuesta."
                      : "Ya existe(n) punto(s) de venta en esta direccion, quieres darlo de alta igualmente ?"
                  }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <table
                        class="table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0 text-sm table-dense"
                        v-if="similarTableData.length > 0"
                      >
                        <tr>
                          <th class="stats-th-title text-center">
                            IdPOS / POS Brand
                          </th>
                          <th class="stats-th-title text-center">POS Name</th>
                          <th class="stats-th-title text-center">Ruta</th>
                          <th class="stats-th-title text-center">Chain</th>
                          <th class="stats-th-title text-center">
                            Full Address
                          </th>
                          <th
                            class="stats-th-title"
                            v-if="ISTHEREAVAILABLEBRANDS"
                          >
                            &nbsp;
                          </th>
                          <th
                            class="stats-th-title"
                            v-if="ISTHERECHANGEABLECHAIN"
                          >
                            &nbsp;
                          </th>
                        </tr>
                        <tr
                          v-for="(posItem, index) in similarTableData"
                          :key="`pos_item_${index}`"
                        >
                          <td>
                            {{ IDPosAndIdPosBrandTitle(posItem) }}
                          </td>
                          <td>{{ posItem.name }}</td>
                          <td>{{ getRouteLabelForTable(posItem) }}</td>
                          <td>{{ posItem?.chain?.name }}</td>
                          <td>
                            {{
                              [
                                posItem.town,
                                posItem.postalCode,
                                posItem.address
                              ].join(", ")
                            }}
                          </td>
                          <td class="px-1 py-1" v-if="ISTHEREAVAILABLEBRANDS">
                            <v-btn
                              color="blue"
                              text
                              v-if="!isCheckExistBrandsInCurrentPos(posItem)"
                              @click="onClickAddMarca(posItem)"
                            >
                              Añadir Marca
                            </v-btn>
                            <p v-else style="padding: 0 20px;">&nbsp;</p>
                          </td>
                          <td class="px-1 py-1" v-if="ISTHERECHANGEABLECHAIN">
                            <v-btn
                              color="blue"
                              text
                              v-if="isCheckChangeableChain(posItem)"
                              @click="onClickChangeChain(posItem)"
                            >
                              {{ `${$t("change")} ${$t("chain")}` }}
                            </v-btn>
                            <p v-else style="padding: 0 20px;">&nbsp;</p>
                          </td>
                        </tr>
                      </table>

                      <p v-else>There is no data !</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  @click="
                    () => {
                      this.closeEditDialog();
                      this.saveData(selectedSavingData);
                    }
                  "
                  v-if="similarPosItemsInSameChain.length === 0"
                >
                  OK
                </v-btn>
                <v-btn color="blue darken-1" text @click="closeEditDialog">
                  {{ $t("cancel") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card elevation="5" outlined>
            <v-card-title>Info cliente</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.name"
                    v-bind:label="$t('brands.name')"
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.storeM2"
                    type="number"
                    v-bind:label="$t('Store m2')"
                    :rules="storem2Rules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.chainId"
                    v-bind:label="$t('chain')"
                    :items="chain_list"
                    item-text="name"
                    item-value="id"
                    filled
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                    @change="onChangeChainId(posData.chainId, 1)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.channelId"
                    v-bind:label="$t('channel')"
                    :items="channel_list"
                    item-text="name"
                    item-value="id"
                    filled
                    required
                    :rules="requiredRules"
                    @change="onChangeChannelId(posData.channelId, 1)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.subChainId"
                    v-bind:label="`Sub ${$t('chain')}`"
                    :items="sub_chain_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                    @change="onChangeChainId(posData.subChainId, 2)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.subChannelId"
                    v-bind:label="`Sub ${$t('channel')}`"
                    :items="sub_channel_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                    @change="onChangeChannelId(posData.subChannelId, 2)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.sub2ChainId"
                    v-bind:label="`Sub-Sub ${$t('chain')}`"
                    :items="sub2_chain_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.sub2ChannelId"
                    v-bind:label="`Sub-Sub ${$t('channel')}`"
                    :items="sub2_channel_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h6 class="my-2">Introduce la nueva dirección:</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="posData.address"
                    v-bind:label="$t('address')"
                    v-if="this.isManualAddress"
                    outlined
                    class="editpos_input"
                    :readonly="this.isManualAddress"
                  ></v-text-field>
                  <input
                    v-model="posData.address"
                    type="text"
                    :placeholder="$t('address')"
                    ref="googleaddressbar"
                    class="googleAddressFinder"
                    v-show="!this.isManualAddress"
                    :readonly="this.isManualAddress"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.town"
                    v-bind:label="$t('town')"
                    required
                    :rules="!this.isManualAddress ? requiredRules : []"
                    hide-details="auto"
                    outlined
                    class="editpos_input"
                    :readonly="this.isManualAddress"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.postalCode"
                    label="CP"
                    required
                    :rules="!this.isManualAddress ? requiredRules : []"
                    hide-details="auto"
                    outlined
                    class="editpos_input"
                    :readonly="this.isManualAddress"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="posData.addressObservation"
                    v-bind:label="$t('address observation')"
                    hide-details="auto"
                    outlined
                    class="editpos_input"
                    :readonly="this.isManualAddress"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mb-1" v-if="manualData" v-show="isManualAddress">
                <v-col cols="12" sm="12" md="12">
                  <h6 class="my-2">
                    Introduce manualmente la nueva dirección:
                  </h6>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="manualData.address"
                        v-bind:label="$t('address')"
                        required
                        :rules="requiredRules"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="manualData.town"
                        v-bind:label="$t('town')"
                        required
                        :rules="requiredRules"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="manualData.postalCode"
                        label="CP"
                        required
                        :rules="requiredRules"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="manualData.addressObservation"
                        v-bind:label="$t('address observation')"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="12">
                  <div class="text-right">
                    <a style="color: #1976d2;" @click="toggleCantfindAddress">
                      La dirección no sale
                    </a>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn
                    color="primary"
                    dark
                    variant="outlined"
                    @click="isMoreContactInfoShow = !isMoreContactInfoShow"
                  >
                    <b-icon
                      icon="plus-circle"
                      font-scale="1.5"
                      class="mr-2"
                      v-if="!isMoreContactInfoShow"
                    ></b-icon>
                    <b-icon
                      icon="dash-circle"
                      font-scale="1.5"
                      class="mr-2"
                      v-if="isMoreContactInfoShow"
                    ></b-icon>
                    Añadir Datos Fiscales
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="isMoreContactInfoShow">
                <v-col cols="12" sm="12" md="12">
                  <contactPersonTable
                    :posData="posData"
                    :updatePosContacts="updatePosContacts"
                    :apiGetBrandsUrl="
                      `worksession/getbasedata?isFlag=searchbrand`
                    "
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-title v-if="isMoreContactInfoShow"
              >Datos fiscales</v-card-title
            >
            <v-divider
              class="mx-4 my-0"
              v-if="isMoreContactInfoShow"
            ></v-divider>
            <v-card-text v-if="isMoreContactInfoShow">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalName"
                    v-bind:label="`Fiscal ${$t('brands.name')}`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.vatNumber"
                    v-bind:label="`CIF`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalTown"
                    v-bind:label="`Fiscal ${$t('town')}`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalPostalCode"
                    v-bind:label="`Fiscal CP`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalAddress"
                    v-bind:label="`Fiscal ${$t('address')}`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-title>Otros datos</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <b-alert variant="warning" :show="!mainRoute.geographyId"
                    ><b>Importante:</b> Introduce la dirección antes de añadir
                    datos del pedido</b-alert
                  >
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <template v-for="i in posData.brandSurveys.length">
                    <v-row :key="i">
                      <v-col cols="12" sm="5" md="5">
                        <v-autocomplete
                          v-model="posData.brandSurveys[i - 1].id"
                          v-bind:label="$t('brands.brand')"
                          v-bind:placeholder="$t('start_typing_to_search')"
                          item-text="name"
                          item-value="id"
                          :items="brandList"
                          filled
                          required
                          :rules="requiredRules"
                          hide-details="auto"
                          @change="
                            onChangeBrand(posData.brandSurveys[i - 1].id, i - 1)
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="5" md="5">
                        <v-autocomplete
                          v-model="posData.brandSurveys[i - 1].surveys"
                          v-bind:label="$t('Form')"
                          v-bind:placeholder="$t('start_typing_to_search')"
                          item-text="name"
                          item-value="id"
                          :items="selectedSurveyList[i - 1]"
                          multiple
                          filled
                          required
                          :rules="requiredRules"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                        class="d-flex align-center justify-content-center"
                      >
                        <b-button
                          @click="onClickRemoveBrandSurveys(i - 1)"
                          style="color: white;"
                          variant="danger"
                          size="sm"
                          v-if="posData.brandSurveys.length > 1"
                        >
                          <b-icon icon="trash"></b-icon>
                          Remove
                        </b-button>
                      </v-col>
                    </v-row>
                  </template>
                  <b-button
                    @click="onClickAddBrandSurveys"
                    style="color: white;"
                    variant="primary"
                    size="sm"
                    class="mt-4"
                  >
                    <b-icon icon="plus-circle"></b-icon>
                    añadir
                  </b-button>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-autocomplete
                    v-model="survey_pedido"
                    v-bind:label="`Pedido`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    :items="['Si', 'No']"
                    filled
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="survey_pedido === 'Si'" cols="12" sm="4" md="4">
                  <v-autocomplete
                    v-model="operator"
                    v-bind:label="`Operador`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    :items="operator_list"
                    item-text="name"
                    item-value="id"
                    filled
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                    :disabled="!mainRoute.geographyId"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="survey_pedido === 'Si'" cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="cod_interno"
                    v-bind:label="`Cod Interno`"
                    hide-details="auto"
                    :rules="requiredCodInterno"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    v-bind:label="$t('Set New Visit Day')"
                    v-model="posData.visitdays"
                    :items="weekdays"
                    item-text="label"
                    item-value="value"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                    hide-details="auto"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelectAllOptions('visitdays')"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              posData.visitdays.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{ iconVisitdays }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    v-bind:label="$t('Set Week')"
                    v-model="posData.settedWeeks"
                    :items="weeks"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                    hide-details="auto"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelectAllOptions('settedWeeks')"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              posData.settedWeeks.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{ iconSettedWeeks }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    v-bind:label="$t('periods')"
                    v-model="posData.periods"
                    :items="periods"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                    hide-details="auto"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelectAllOptions('periods')"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              posData.periods.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{ iconPeriods }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="text-right">
                <v-col cols="12" sm="12" md="12" justify="end">
                  <v-btn
                    color="primary"
                    dark
                    @click="isMoreImageShow = !isMoreImageShow"
                  >
                    <b-icon
                      icon="plus-circle"
                      font-scale="1.5"
                      class="mr-2"
                      v-if="!isMoreImageShow"
                    ></b-icon>
                    <b-icon
                      icon="dash-circle"
                      font-scale="1.5"
                      class="mr-2"
                      v-if="isMoreImageShow"
                    ></b-icon>
                    Añadir Fotos y Comentarios</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="isMoreImageShow">
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-bind:label="$t('comments')"
                    v-model="posData.gpvComments"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="isMoreImageShow">
                <template v-for="i in attachmentLimitCount">
                  <v-col cols="12" sm="12" md="6" :key="i" class="text-center">
                    <h6 class="my-2">{{ $t("Picture") }} {{ i }}</h6>
                    <v-btn
                      color="primary"
                      dark
                      @click="onUploadExcelClick(i)"
                      class="btn_select_images"
                    >
                      Añade Foto
                    </v-btn>
                    <input
                      :ref="`uploaderPhotos`"
                      :class="`d-none ASDFSDF_${i}`"
                      type="file"
                      accept=".jpg,.png"
                      @change="onFileChanged"
                    />
                    <p class="my-1" v-if="posData.attachments[i - 1]?.img">
                      {{ posData.attachments[i - 1]?.img?.name }}
                    </p>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>

            <v-card-actions class="d-flex justify-content-center">
              <v-btn
                color="success"
                dark
                class="mx-1 my-2"
                :loading="isSaving"
                @click="onSaveClick(posData)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <b-alert v-if="!posData" show variant="info">
            <b-icon
              icon="arrow-counterclockwise"
              animation="spin-reverse"
            ></b-icon>
            cargando datos...
          </b-alert>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import contactPersonTable from "@/components/contactPersonTable";
import haversine from "haversine-distance";

export default {
  name: "worksessionNewPos",
  props: ["worksessionId"],
  components: {
    contactPersonTable
  },
  data: function() {
    return {
      currentChoosedFileTagIndex: null,
      isMoreImageShow: false,
      isMoreContactInfoShow: false,
      mainRoute: null,
      posData: null,
      manualData: null,
      chain_list: [],
      channel_list: [],
      sub_chain_list: [],
      sub_channel_list: [],
      sub2_chain_list: [],
      sub2_channel_list: [],
      weekCount: 4,
      periodsCount: 4,
      weekdays: [],
      weeks: [1, 2, 3, 4],
      periods: [],
      attachmentLimitCount: 4,
      brandList: [],
      surveyList: [],
      selectedSurveyList: [],

      valid: true,
      requiredRules: [v => !!v || "Required"],
      emailRules: [
        // (v) => !!v || "Required",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      requiredEmailRules: [
        v => !!v || "Required",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      requiredCodInterno: [
        v =>
          (!isNaN(v) && v.length < 13) ||
          "Incorrect Value ! Maximum Length:12, Value:Integer"
      ],
      storem2Rules: [
        v =>
          !v ||
          (v >= 0 && v <= 2000) ||
          "Incorrect value ! this value should be between 0 and 2000."
      ],
      survey_pedido: null,
      cod_interno: "",
      operator: "",
      operator_list: [],
      isSaving: false,
      addressAutoComplete: null,

      isManualAddress: false,
      isTooFarSelectedAddress: false,
      googleAddressPt: null,

      /********** create or edit dialog options **********/
      showConfirmDialog: false,
      selectedSavingData: null,
      isFindSameChain: false,
      isFindOtherChain: false,
      similarPosItemsInSameChain: [],
      similarPosItemsInDifferentChains: []
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", ["currentPosition"]),
    ISTHEREAVAILABLEBRANDS() {
      let ret = false;
      if (this.showConfirmDialog && this.similarTableData.length > 0) {
        this.similarTableData.map(posItem => {
          ret = ret || !this.isCheckExistBrandsInCurrentPos(posItem);
        });
      }
      return ret;
    },
    ISTHERECHANGEABLECHAIN() {
      let ret = false;
      if (this.showConfirmDialog && this.similarTableData.length > 0) {
        this.similarTableData.map(posItem => {
          ret = ret || this.isCheckChangeableChain(posItem);
        });
      }
      return ret;
    },
    similarTableData() {
      if (this.isFindSameChain) {
        return this.similarPosItemsInSameChain;
      } else {
        return this.similarPosItemsInDifferentChains;
      }
    },
    likesAllPeriods() {
      return this.posData.periods.length === this.periods.length;
    },
    iconPeriods() {
      if (this.likesAllPeriods) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllSettedWeeks() {
      return this.posData.settedWeeks.length === this.weeks.length;
    },
    iconSettedWeeks() {
      if (this.likesAllSettedWeeks) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllVisitdays() {
      return this.posData.visitdays.length === this.weekdays.length;
    },
    iconVisitdays() {
      if (this.likesAllVisitdays) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    async onChangeChainId(parentId, subDepth) {
      let fetch_url = `worksession/getbasedata?isFlag=chainlist`;
      console.log(parentId, subDepth);
      let chainList = [];
      if (parentId) {
        fetch_url += "&parentId=" + parentId;
        let { chain_list } = await ApiService.post(fetch_url);
        chainList = chain_list;
      }
      if (subDepth === 1) {
        this.sub_chain_list = chainList;
        this.sub2_chain_list = [];
      } else if (subDepth === 2) {
        this.sub2_chain_list = chainList;
      }
    },
    async onChangeChannelId(parentId, subDepth) {
      let fetch_url = `worksession/getbasedata?isFlag=channel_list`;
      console.log(parentId, subDepth);
      let channelList = [];
      if (parentId) {
        fetch_url += "&parentId=" + parentId;
        let { channel_list } = await ApiService.post(fetch_url);
        channelList = channel_list;
      }
      if (subDepth === 1) {
        this.sub_channel_list = channelList;
        this.sub2_channel_list = [];
      } else if (subDepth === 2) {
        this.sub2_channel_list = channelList;
      }
    },
    placeChangedListener() {
      var address = this.addressAutoComplete.getPlace();
      console.log(address);
      if (address && address.address_components) {
        let addressDetail = {
          address: address.formatted_address,
          town: null,
          postalCode: null,
          latitude: null,
          longitude: null
        };
        // types - postal_code
        let postal_code_item = address.address_components.filter(el =>
          el.types.includes("postal_code")
        );
        if (postal_code_item && postal_code_item.length > 0) {
          addressDetail.postalCode = postal_code_item[0].long_name;
        }
        // town types - locality
        let town_item = address.address_components.filter(el =>
          el.types.includes("locality")
        );
        if (town_item && town_item.length > 0) {
          addressDetail.town = town_item[0].long_name;
        }
        if (address.geometry) {
          addressDetail.latitude = address.geometry.location.lat();
          addressDetail.longitude = address.geometry.location.lng();

          let currentLocationPt = {
            lat: this.currentPosition.latitude,
            lng: this.currentPosition.longitude
          };
          let googleAddressPt = {
            lat: addressDetail.latitude,
            lng: addressDetail.longitude
          };
          this.googleAddressPt = {
            latitude: addressDetail.latitude,
            longitude: addressDetail.longitude
          };
          let haversine_m = haversine(googleAddressPt, currentLocationPt); //Results in meters (default)
          // debug for newpos
          // if (haversine_m > 2478881) {
          // if (haversine_m > 200) {
          //   logError(
          //     "La distancia entre la dirección ingresada y las coordenadas actuales es muy grande. revisa la dirección."
          //   );
          //   this.isTooFarSelectedAddress = true;
          // } else {
          //   this.isTooFarSelectedAddress = false;
          // }
        }

        this.posData = { ...this.posData, ...addressDetail };
      }
    },
    toggleSelectAllOptions(flag) {
      if (flag === "periods") {
        if (this.likesAllPeriods) {
          this.posData.periods = [];
        } else {
          this.posData.periods = this.periods;
        }
      } else if (flag === "settedWeeks") {
        if (this.likesAllSettedWeeks) {
          this.posData.settedWeeks = [];
        } else {
          this.posData.settedWeeks = this.weeks;
        }
      } else if (flag === "visitdays") {
        if (this.likesAllVisitdays) {
          this.posData.visitdays = [];
        } else {
          this.posData.visitdays = this.weekdays.map(el => el.value);
        }
      }
    },
    onPicture(img) {
      for (let i = 0; i < this.attachmentLimitCount; i++) {
        if (this.$refs["pictureInput"][i].file) {
          this.posData.attachments[i].img = this.$refs["pictureInput"][i].file;
        } else {
          this.posData.attachments[i].img = null;
        }
      }
    },
    onClickAddBrandSurveys() {
      if (this.posData) {
        let push_item = { id: null, surveys: null };
        this.posData.brandSurveys.push(push_item);
      }
    },
    onClickRemoveBrandSurveys(removeIndex) {
      if (this.posData.brandSurveys.length > 1) {
        this.posData.brandSurveys = [
          ...this.posData.brandSurveys.slice(0, removeIndex),
          ...this.posData.brandSurveys.slice(removeIndex + 1)
        ];
        this.selectedSurveyList = [
          ...this.selectedSurveyList.slice(0, removeIndex),
          ...this.selectedSurveyList.slice(removeIndex + 1)
        ];
      }
    },
    createSelected() {
      let model = {
        routeId: this.mainRoute.id,
        name: null,
        chainId: null,
        channelId: null,
        subChainId: null,
        subChannelId: null,
        sub2ChainId: null,
        sub2ChannelId: null,
        town: null,
        postalCode: null,
        address: null,
        addressObservation: null,
        contact: null,
        email: null,
        phone: null,
        phone2: null,
        fiscalName: null,
        vatNumber: null,
        fiscalTown: null,
        fiscalPostalCode: null,
        fiscalAddress: null,
        visitdays: [],
        settedWeeks: [],
        periods: [],
        gpvComments: null,
        attachments: [],
        brandSurveys: [],
        pos_contacts: []
      };
      let i = 0,
        brandCount = 1;
      for (i = 0; i < brandCount; i++) {
        let push_item = { id: null, surveys: null };
        this.selectedSurveyList[i] = [];
        model.brandSurveys.push(push_item);
      }
      for (i = 0; i < this.attachmentLimitCount; i++) {
        let push_item = {
          img: null
        };
        model.attachments.push(push_item);
      }
      return model;
    },
    async saveData(posData) {
      let body = _.omit(posData, ["attachments"]);
      this.isSaving = true;
      try {
        let attachmentList = posData["attachments"];
        let attachmentIdList = [];
        for (const attachmentItem of attachmentList) {
          if (attachmentItem.img) {
            let { data } = await ApiService.uploadImage(attachmentItem.img);
            attachmentIdList.push(data.id);
          }
        }
        body["attachmentIdList"] = attachmentIdList;
        body = {
          ...body,
          survey_pedido: this.survey_pedido,
          operator: this.operator,
          cod_interno: this.cod_interno
        };
        console.log(body);
        if (body) {
          let data = await ApiService.post(`worksession/createnewpos`, body);
          if (data.posId) {
            if (data?.message) {
              logInfo(data.message);
            } else {
              logInfo("Éxito");
            }
            this.$router.push({
              name: "router_worksessionroute",
              params: {
                id: this.worksessionId
              }
            });
          } else if (!data.success) {
            logError(data.message);
          }
        }
        this.isSaving = false;
      } catch (error) {
        this.isSaving = false;
      }
    },
    closeEditDialog() {
      this.showConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedSavingData = null;
        this.similarPosItemsInDifferentChains = [];
        this.similarPosItemsInSameChain = [];
      });
    },
    IDPosAndIdPosBrandTitle(posItem) {
      let title = "";
      if (posItem) {
        let idPosBrand = this.getIdPosBrand(posItem);
        title = posItem?.id;
        if (idPosBrand) {
          title += ` - ${idPosBrand}`;
        }
      }
      return title;
    },
    getIdPosBrand(posItem) {
      let idPosBrands = [];
      if (posItem && posItem?.route_pos) {
        posItem.route_pos.map(el => {
          if (el?.brand && el?.brand?.pos && el?.brand?.pos.length > 0) {
            let posbrands = el?.brand?.pos.map(
              posbrandEl => posbrandEl?.posbrands?.idPosBrand
            );
            idPosBrands = [...idPosBrands, ...posbrands];
          }
        });
      }
      return idPosBrands.join(", ");
    },
    getRouteLabelForTable(posItem) {
      let routes = this.getRouteList(posItem);
      let label = "";
      if (routes.length > 0) {
        label = routes.map(el => el.name).join(",");
      }
      return label;
    },
    getRouteList(posItem) {
      let routes = [];
      if (posItem && posItem?.route_pos && posItem?.route_pos.length > 0) {
        routes = posItem?.route_pos.map(el => el.route);
      }
      return routes;
    },
    isCheckExistBrandsInCurrentPos(posItem) {
      let notExistIdBrands = this.getNotExistIdBrands(posItem);
      return notExistIdBrands.length === 0;
    },
    isCheckChangeableChain(posItem) {
      let ret = false;
      if (posItem?.chainId !== this.posData?.chainId) {
        ret = true;
      }
      return ret;
    },
    getNotExistIdBrands(posItem) {
      let ret = [];
      if (posItem) {
        const { route_pos } = posItem;
        let existBrands = [];
        let existSurveys = [];
        route_pos.map(routePosItem => {
          if (routePosItem?.brand) existBrands.push(routePosItem.brand);
          if (routePosItem?.surveys && routePosItem.surveys.length > 0) {
            existSurveys = [...existSurveys, ...routePosItem.surveys];
          }
        });
        if (existBrands.length > 0) {
          existBrands = _.uniq(existBrands, "id");
        }
        if (existSurveys.length > 0) {
          existSurveys = _.uniq(existSurveys, "id");
        }
        console.log("existBrands existSurveys - ", existBrands, existSurveys);
        if (
          this.posData &&
          this.posData?.brandSurveys &&
          this.posData?.brandSurveys.length > 0
        ) {
          let additionalIdBrands = this.posData.brandSurveys.map(el => el.id);
          let existIdBrands = existBrands.map(el => el.id);
          let notExistIdBrands = additionalIdBrands.filter(
            el => !existIdBrands.includes(el)
          );
          ret = notExistIdBrands;
        }
      }
      return ret;
    },
    async onClickChangeChain(posItem) {
      let chainId = this.posData?.chainId;
      if (!chainId) {
        logError("You should choose chain in the form.");
        return;
      }
      const { id } = posItem;
      let form_data = {
        posId: id,
        change_chainId: chainId
      };
      try {
        const response = await ApiService.post(
          `worksession/change_new_pos_chain_id`,
          form_data
        );
        logInfo("Éxito !");
        this.$router.push({
          name: "router_worksessionroute",
          params: {
            id: this.worksessionId
          }
        });
      } catch (err) {
        logError("Error !");
      }
    },
    async onClickAddMarca(posItem) {
      console.log("posItem - ", posItem);
      if (posItem) {
        if (
          this.posData &&
          this.posData?.brandSurveys &&
          this.posData?.brandSurveys.length > 0
        ) {
          let notExistIdBrands = this.getNotExistIdBrands(posItem);
          if (notExistIdBrands.length > 0) {
            // can only add brands and surveys
            let new_additional_brand_surveys = this.posData.brandSurveys.filter(
              el => notExistIdBrands.includes(el.id)
            );
            let form_data = {
              routeId: this.posData.routeId,
              brandSurveys: new_additional_brand_surveys,
              periods: this.posData.periods,
              visitdays: this.posData.visitdays,
              settedWeeks: this.posData.settedWeeks,
              posId: posItem.id
            };
            const response = await ApiService.post(
              `worksession/addmarcasurveysfornewpos`,
              form_data
            );
            logInfo("Éxito");
            this.closeEditDialog();
            this.$router.push({
              name: "router_worksessionroute",
              params: {
                id: this.worksessionId
              }
            });
          } else {
            // exito !  -  already exists the brands
            if (
              window.confirm(
                "Esta Marca ya existe para este punto de venta, abre el punto de venta y solicita los cambios que consideres oportunos."
              )
            ) {
              logInfo("Éxito");
              this.closeEditDialog();
            }
          }
        }
      }
    },
    async onSaveClick(posData) {
      if (this.$refs.form.validate()) {
        if (this.isTooFarSelectedAddress) {
          logError(
            "La distancia entre la dirección ingresada y las coordenadas actuales es muy grande. revisa la dirección."
          );
          return;
        }
        if (!window.confirm("¿Está realmente desea guardar este POS?")) return;
        this.isSaving = true;
        try {
          let body = _.omit(posData, ["attachments"]);
          if (this.isManualAddress) {
            body = {
              ...body,
              ...this.manualData
            };
            if (this.currentPosition) {
              body = {
                ...body,
                ...this.currentPosition
              };
            }
          }
          if (!body?.latitude || !body?.longitude) {
            if (this.googleAddressPt) {
              body = {
                ...body,
                latitude: this.googleAddressPt?.latitude,
                longitude: this.googleAddressPt?.longitude
              };
            } else {
              logError("You should enter la direccion no sale");
              this.isManualAddress = true;
              return;
            }
          }
          let data = await ApiService.post(
            `worksession/checksameposindifferentchain`,
            body
          );
          this.isSaving = false;
          body = {
            ...body,
            attachments: posData["attachments"]
          };
          if (data?.success) {
            this.similarPosItemsInDifferentChains = [];
            this.similarPosItemsInSameChain = [];
            if (data?.similarPosItemsInDifferentChains) {
              this.similarPosItemsInDifferentChains =
                data.similarPosItemsInDifferentChains;
            }
            if (data?.similarPosItemsInSameChain) {
              this.similarPosItemsInSameChain = data.similarPosItemsInSameChain;
            }
            body["filteredPosId"] = data.filteredPosId;
            if (data?.isFindSameChain) {
              logError(
                "Este Punto de venta ya existe, por lo que no podemos darlo de alta."
              );
            }
            this.isFindSameChain = data.isFindSameChain;
            this.isFindOtherChain = data.isFindOtherChain;
            if (data?.isFindSameChain || data?.isFindOtherChain) {
              // show confirmation dialog with similarPosItemsIndifferentchains
              // in the dialog, click yes button - //   await this.saveData(body);
              this.showConfirmDialog = true;
              this.selectedSavingData = body;
            }
          } else {
            await this.saveData(body);
          }
        } catch (error) {
          this.isSaving = false;
        }
      } else {
        logError("Por favor marque los campos requeridos.");
        const errors = [];
        this.$refs.form.inputs.forEach(e => {
          if (e.errorBucket && e.errorBucket.length) {
            errors.push(...e.errorBucket);
          }
        });
      }
    },
    updatePosContacts(newPosContacts) {
      this.posData.pos_contacts = newPosContacts;
    },
    onChangeBrand(selectedBrandId, index) {
      console.log(selectedBrandId);
      console.log(this.surveyList);
      if (selectedBrandId) {
        this.selectedSurveyList[index] = this.surveyList.filter(
          el => el.brandId === selectedBrandId
        );
      } else {
        this.selectedSurveyList[index] = this.surveyList;
      }
      console.log(this.surveyList);
    },
    onUploadExcelClick(index) {
      window.addEventListener(
        "focus",
        () => {
          // this.isSelecting = false;
        },
        { once: false }
      );
      if (index <= this.attachmentLimitCount && index > 0) {
        this.currentChoosedFileTagIndex = index - 1;
        this.$refs.uploaderPhotos[`${index - 1}`].click();
      }
    },
    async onFileChanged(e) {
      if (e.target.files.length > 0) {
        this.posData.attachments[this.currentChoosedFileTagIndex].img =
          e.target.files[0];
      } else {
        this.posData.attachments[this.currentChoosedFileTagIndex].img = null;
        this.currentChoosedFileTagIndex = null;
      }
    },
    async toggleCantfindAddress() {
      this.isManualAddress = !this.isManualAddress;
      if (this.isManualAddress) {
        this.manualData = {
          town: "",
          postalCode: "",
          address: "",
          addressObservation: ""
        };
      }
    },
    async init() {
      let {
        chain_list,
        weekdays,
        weekCount,
        periodsCount,
        brandList,
        surveyList
      } = await ApiService.post(`worksession/getbasedata?isFlag=newpos`);
      this.chain_list = chain_list;
      let { channel_list } = await ApiService.post(
        `worksession/getbasedata?isFlag=channel_list`
      );
      let { operator_list } = await ApiService.post(
        `worksession/getbasedata?isFlag=operator_list`
      );
      this.channel_list = channel_list;
      this.weekdays = weekdays;
      this.weekCount = weekCount;
      this.brandList = brandList;
      this.surveyList = surveyList;
      this.weeks = [];
      for (let i = 1; i <= weekCount; i++) {
        this.weeks.push(i);
      }
      this.periodsCount = periodsCount;
      for (let i = 1; i <= periodsCount; i++) {
        this.periods.push(i);
      }
      let fetch_url = `worksession/${this.worksessionId}`;
      const {
        data,
        meta,
        visitTypes,
        user_route,
        todayInfo
      } = await ApiService.get(fetch_url);
      if (user_route) {
        if (Array.isArray(user_route) && user_route.length > 0) {
          this.mainRoute = user_route[0];
        } else {
          this.mainRoute = user_route;
        }
        this.operator_list = this.mainRoute.operatorOpts;
        this.posData = this.createSelected();
        if (todayInfo) {
          if (this.weekdays[todayInfo?.day]) {
            this.posData.visitdays = [this.weekdays[todayInfo.day].value];
          }
          this.posData.settedWeeks = [todayInfo?.week];
          this.posData.periods = [todayInfo?.period];
        }
        this.$nextTick(function() {
          this.addressAutoComplete = new window.google.maps.places.Autocomplete(
            this.$refs.googleaddressbar
          );
          this.addressAutoComplete.addListener(
            "place_changed",
            this.placeChangedListener
          );
        });
      }
    }
  },

  async mounted() {
    await this.init();
  }
};
</script>

<style>
.picture-inner,
.picture-preview {
  z-index: 1000 !important;
}
input.googleAddressFinder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-property: height, min-height;
  margin-top: 16px;
  font-size: 16px;
  height: 33px;
}
</style>
